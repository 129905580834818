import { Entry, IStorage } from './Contract';
import { Lock } from '../../kit/Lock';
import _ from 'lodash';
import {Map as ImmutableMap} from "immutable";
import Events from "events";

/**
 * This memory cache acts as a Write-Through-Cache
 * meaning it blocks on writes but does its best to
 * return cached reads.
 *
 * The goal is to ensure all reads come from the cache
 * at the expense of an initial load time.
 *
 * The memory cache expects that all application code
 * will read/write through it so that it can optimistically
 * update its cache when a write is encountered
 */
export class MemoryCache<T> implements IStorage<T> {
  static async open<T>(name: string, storage: IStorage<T>, idFn?: (obj: T) => string): Promise<MemoryCache<T>> {
    return new MemoryCache<T>(name, storage, await storage.all(), idFn);
  }

  public compressOnSave = false;
  private static readonly subscribers = new Events();
  private readonly lock = new Lock();
  private immutableMap: ImmutableMap<string, T>;
  private _changeCount = 0;
  private _changePending = false;

  public readonly name: string;

  get map() {
    return this.immutableMap as ReadonlyMap<string, T>;
  }

  get changeCount() {
    return this._changeCount;
  }

  get length() {
    return this.immutableMap.size;
  }

  constructor(name: string, private storage: IStorage<T>, cache: ReadonlyMap<string, T>, public get_key: ((obj: T) => string) | undefined) {
    this.name = name;
    this.immutableMap = ImmutableMap(cache);
  }

  // Resets the cache by loading the values which match the filter.
  async reset(loadFilter?: (obj: T) => Promise<boolean>) {
    await this.lock.run(async () => {
      this.changed(ImmutableMap(await this.storage.some(loadFilter)));
    });
  }

  async get(key: string): Promise<T | undefined> {
    return Object.freeze(this.immutableMap.get(key));
  }

  async set(key: string, value: T): Promise<void> {
    await this.lock.run(async () => {
      await this.storage.set(key, value, this.compressOnSave);
      const existingEntry = this.immutableMap.get(key);
      if (!_.isEqual(value, existingEntry)) {
        const newMap = this.immutableMap.set(key, value);
        this.changed(newMap);
      }
    });
  }
  async setMany(entries: Entry<T>[]): Promise<void> {
    if (!entries || entries.length === 0) {
      return;
    }
    await this.lock.run(async () => {
      await this.storage.setMany(entries, this.compressOnSave);
      const newValues: [string, T][] = [];

      for (const entry of entries) {
        const existingEntry = this.immutableMap.get(entry.key);
        if (!_.isEqual(entry.value, existingEntry)) {
          newValues.push([entry.key, entry.value]);
        }
      }

      if (newValues.length) {
        const newMap = this.immutableMap.merge(newValues);
        this.changed(newMap);
      }
    });
  }

  async delete(key: string): Promise<void> {
    return await this.deleteMany([key]);
  }
  async deleteMany(keys: string[]): Promise<void> {
    if (keys.length === 0) {
      // Nothing to do.
      return;
    }
    await this.lock.run(async () => {
      await this.storage.deleteMany(keys);
      this.changed(this.immutableMap.deleteAll(keys));
    });
  }

  async has(key: string): Promise<boolean> {
    return this.immutableMap.has(key);
  }

  async all(): Promise<ReadonlyMap<string, T>> {
    return this.immutableMap;
  }
  async some(loadFilter?: (obj: T) => Promise<boolean>): Promise<ReadonlyMap<string, T>> {
    const result: Map<string, T> = new Map();
    for(const [key, value] of (await this.all()).entries()) {
      if (!loadFilter || await loadFilter(value)) {
        result.set(key, value);
      }
    }
    return result;
  }

  async iterate(_: (entry: Entry<T>) => void): Promise<void> {
    // at this stage the application doesn't need this method
    // but we'll implement it if needed later on.
    throw new Error('Method not implemented.');
  }

  async clear(): Promise<void> {
    await this.storage.clear();
    const newMap = this.immutableMap.clear();
    this.changed(newMap);
  }

  private changed(newMap: ImmutableMap<string, T>): void {
    if (newMap === this.immutableMap) {
      return;
    }
    this.immutableMap = newMap;
    this._changeCount++;
    if (!this._changePending) {
      this._changePending = true;
      setTimeout(() => {
        this._changePending = false;
        MemoryCache.subscribers.emit("change", this);
      });
    }
  }

  static subscribe(callback: (m: MemoryCache<any>) => void) {
    MemoryCache.subscribers.addListener("change", callback);
    return () => {
      MemoryCache.subscribers.removeListener("change", callback);
    }
  }
}
