import {useSyncCenter} from "../../../syncstream/SyncCenterProvider";
import {useMap, useStore} from "../../../core/storage/hooks/UseStore";
import {useEffect, useState} from "react";

const useDashboardData = () => {
  const [indexedDbData, setIndexedDbData] = useState<{ dataType: string, numberOfRecords: number, queueSize: string }[]>([{ dataType: '', numberOfRecords: 0, queueSize: '' }]);

  const syncCenter = useSyncCenter();
  useEffect(() => {
    setIndexedDbData([
      {dataType: 'Drugs', numberOfRecords: syncCenter.drugs.store.length, queueSize: ''},
      {dataType: 'Patients', numberOfRecords: syncCenter.patients.store.length, queueSize: ''},
      {dataType: 'Packed Patients Days', numberOfRecords: syncCenter.packedPatientDays.store.length, queueSize: ''},
      {dataType: 'Packed Patient PRN Medication', numberOfRecords: syncCenter.packedPatientPrnMedications.store.length, queueSize: ''},
      {dataType: 'Syringe Driver Activities', numberOfRecords: syncCenter.syringeDriverActivity.store.length, queueSize: syncCenter.syringeDriverActivity.queue.queueLength.toString()},
      {dataType: 'Patch Observations', numberOfRecords: syncCenter.patchObservations.store.length, queueSize: syncCenter.patchObservations.queue.queueLength.toString()},
      {dataType: 'Dose Rounds', numberOfRecords: syncCenter.rounds.store.length, queueSize: syncCenter.rounds.queue.queueLength.toString()},
      {dataType: 'Orders', numberOfRecords: syncCenter.orders.store.length, queueSize: ''},
    ])
  }, [
      syncCenter.drugs.store,
      syncCenter.patients.store,
      syncCenter.packedPatientDays.store,
      syncCenter.packedPatientPrnMedications.store,
      syncCenter.syringeDriverActivity.store,
      syncCenter.syringeDriverActivity.queue,
      syncCenter.patchObservations.store,
      syncCenter.patchObservations.queue,
      syncCenter.rounds.store,
      syncCenter.orders.store
  ]);

  return {indexedDbData};
}

export default useDashboardData;
